/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    h4: "h4",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Networking containers enable a containerized app to communicate with the outside world. In many cases a given app may be dependent on a database or multiple services. We can \"expose\" to enable these interactions."), "\n", React.createElement(_components.h2, null, "Expose"), "\n", React.createElement(_components.h3, null, "Dockerfile"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-dockerfile:title=dockerfile"
  }, "...\nEXPOSE 8080 \n...\n")), "\n", React.createElement(_components.p, null, "We can also expose multiple ports"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-dockerfile:title=dockerfile"
  }, "...\nEXPOSE 80\nEXPOSE 8080 \nEXPOSE 5432\n...\n")), "\n", React.createElement(_components.h3, null, "CLI and Run Time"), "\n", React.createElement(_components.h4, null, "At ", React.createElement(_components.code, null, "docker start")), "\n", React.createElement(_components.h4, null, "At ", React.createElement(_components.code, null, "docker run")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run --expose=8080 --name=\"port-demo\" test\n")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.mend.io/free-developer-tools/blog/docker-expose-port/"
  }, "Docker Expose Ports - mend.io")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
